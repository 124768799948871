import { IPartialConfigOptions } from 'ng2-ui-auth/lib/config-interfaces';
import { environment } from "../../environments/environment";

// TODO

// export class OAuthSettings extends IPartialConfigOptions
// {
//     defaultHeaders = {
//         'Content-Type': 'application/json'
//     };
//     baseUrl = environment.api;
//     providers = {
//         linkedin: {
//             clientId: environment.linkedIn.clientId
//         },
//         xing: {
//             oauthType: '1.0',
//             clientId: '7b338f6388d87ce38f63',
//             url: '/auth/xing',
//             authorizationEndpoint: 'https://api.xing.com/v1/authorize',
//             redirectUri: null,
//         }
//     };
// }


export const myAuthConfig: IPartialConfigOptions = {
    // loginUrl: '/api/auth/v1/login',
    // signupUrl: '/api/auth/v1/signup',
    //
    // unlinkUrl: '/api/auth/v1/me/unlink',
    baseUrl: environment.api,
    providers: {
        linkedin: {
            name: 'linkedin-congreet',
            clientId: environment.linkedIn.clientId,
            scope: ['r_emailaddress','r_liteprofile']
        },
        // google: {
        //     url: '/api/auth/v1/a/google',
        //     redirectUri: window.location.protocol + '//' + window.location.host + '/api/auth/v1/blank',
        // },
        // facebook: {
        //     url: '/api/auth/v1/a/facebook',
        //     redirectUri: window.location.protocol + '//' + window.location.host + '/api/auth/v1/blank',
        // },
        // github: {
        //     url: '/api/auth/v1/a/github',
        //     redirectUri: window.location.protocol + '//' + window.location.host + '/api/auth/v1/blank',
        // },
        // twitter: {
        //     url: '/api/auth/v1/a/twitter',
        //     redirectUri: window.location.protocol + '//' + window.location.host + '/api/auth/v1/blank',
        // }
    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'striphtml'
})

export class StripHtmlPipe implements PipeTransform {
    transform(value: string, escape: boolean = false): any {
        if (escape) {
            return value.replace(/</g, "&lt;").replace(/>/g, "&gt;");
        } else {
            return value.replace(/<.*?>/g, ''); // replace tags
        }
    }
}
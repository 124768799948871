import { Event } from "./event";
import { GroupRight } from "./group-right";
import { AttendeesTab } from "./attendees-tab";
import { EventSetting } from "./event-setting";

import * as moment from "moment";
import { DatePipe } from "@angular/common";

export class Group
{
    id: number;
    event_id: number;
    event: Event;
    sort: number;
    name: string;
    icon: string;
    color: string;
    created_at: string;
    date_created_at: Date = new Date();
    checked: boolean = false;

    rights: GroupRight[];

    allow_newsfeed_post: boolean = false;
    allow_newsfeed_comment: boolean = true;
    allow_chat_group_creation: boolean = false;
    allow_call: boolean = false;
    allow_private_chat_group_creation: boolean = false;
    allow_call_chat_group: boolean = false;
    allow_secured_chat_group_creation: boolean = false;

    show_wizard: boolean = false;

    attendees_tab_id: number;
    show_label: boolean = false;
    label: string;
    attendees_tab: AttendeesTab;

    event_setting: EventSetting;

    /**
     * constructor
     *
     * @return void
     */
    public constructor(init?: Group)
    {
        if (!!init)
        {
            Object.assign(this, init);

            this.date_created_at = moment.utc(this.created_at).toDate();

            if (this.event_setting) {
                this.event_setting = new EventSetting(this.event_setting);
            }
        }
        else
        {
            const datePipe = new DatePipe('de-DE');
            this.created_at = datePipe.transform(this.date_created_at, 'YYYY-MM-DD HH:mm:ss');
        }
    }
}

export const availableRights = [
    'visible',
    'profile_details',
    'profile_picture',
    'matching',
    'message',
    'video_call',
    'newsfeed'
];

export const GroupIcons = [{
    value: 'organizer.svg',
    ico: 'organizer.svg',
    name: 'Organizer'
},
{
    value: 'organizer_1.svg',
    ico: 'organizer_1.svg',
    name: 'Organizer'
},
{
    value: 'exhibitor.svg',
    ico: 'exhibitor.svg',
    name: 'Exhibitor'
},
{
    value: 'seller.svg',
    ico: 'seller.svg',
    name: 'Seller'
},
{
    value: 'company.svg',
    ico: 'company.svg',
    name: 'Company'
},
{
    value: 'pitch.svg',
    ico: 'pitch.svg',
    name: 'Pitch'
},
{
    value: 'speaker.svg',
    ico: 'speaker.svg',
    name: 'Speaker'
},
{
    value: 'attendee.svg',
    ico: 'attendee.svg',
    name: 'Attendee'
}];

export const GroupColors = [{
    value: '#4a4e4d',
    color: '#4a4e4d',
    name: 'Grayish cyan'
},{
    value: '#63ace5',
    color: '#63ace5',
    name: 'Soft blue'
},
{
    value: '#0e9aa7',
    color: '#0e9aa7',
    name: 'Dark cyan'
},
{
    value: '#3da4ab',
    color: '#3da4ab',
    name: 'Moderate cyan'
},
{
    value: '#ee4035',
    color: '#ee4035',
    name: 'Bright red'
},
{
    value: '#fe8a71',
    color: '#fe8a71',
    name: 'Soft red'
},
{
    value: '#f6cd61',
    color: '#f6cd61',
    name: 'Soft orange'
},
{
    value: '#f9caa7',
    color: '#f9caa7',
    name: 'Very soft orange'
}];

import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';

// ionic
import { Events } from "../helpers/events";

// rxjs
import { take, share, tap, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { JwtHelperService } from "@auth0/angular-jwt";
import { TranslateService } from "@ngx-translate/core";

// services
import { ServiceLocatorService } from "./service-locator.service";
import { OverlayService } from "./overlay.service";
import { PlatformService } from "./platform.service";

// helpers
import { isExternalUrl } from "../helpers/url";

import { environment } from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class RequestInterceptService implements HttpInterceptor {

    /**
     * translator
     *
     * @param {TranslateService}
     */
    public translate: TranslateService;

    /**
     * static environment config
     *
     * @type {Object}
     */
    public env = environment;

    /**
     * constructor
     *
     * @param appEvents
     * @param jwtHelper
     * @param storage
     */
    constructor(
        public router: Router,
        public appEvents: Events,
        public jwtHelper: JwtHelperService,
        public plt: PlatformService,
        public overlayService: OverlayService
    ) {
        // TODO[jg]
        // super(backend, defaultOptions);
        //
        // // Block local webview caching of XHR requests
        // defaultOptions.headers.append('Content-Type', 'application/json');
        // defaultOptions.headers.append('Cache-Control', 'no-cache');
        // defaultOptions.headers.append('Cache-Control', 'no-store');
        // defaultOptions.headers.append('Cache-Control', 'must-revalidate');
        // defaultOptions.headers.append('Pragma', 'no-cache');
        // defaultOptions.headers.append('Expires', '0');
    }

    compareVersion(v1, v2) {
        if (typeof v1 !== 'string') return false;
        if (typeof v2 !== 'string') return false;
        v1 = v1.split('.');
        v2 = v2.split('.');
        const k = Math.min(v1.length, v2.length);
        for (let i = 0; i < k; ++i) {
            v1[i] = parseInt(v1[i], 10);
            v2[i] = parseInt(v2[i], 10);
            if (v1[i] > v2[i]) return 1;
            if (v1[i] < v2[i]) return -1;
        }
        return v1.length == v2.length ? 0 : (v1.length < v2.length ? -1 : 1);
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<any> {

        let config = {};

        // for mobile add pwa origin to help us with all custom setup based on domain
        if (this.plt.is('mobile')) {
            config = {
                headers: req.headers.set('x-requested-with', environment.pwa),
            }
        }

        const reqCopy = req.clone(config);

        return next.handle(reqCopy).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    if (evt.body && evt.body.success) {
                        // Version changed
                        if (!isExternalUrl(req.url)) {
                            if (this.plt.is('cordova')) {
                                let version = evt.body.success.headers.get("X-Current-APP-Version");
                                let force = evt.body.success.headers.get("X-Force-APP-Version");
                                if (this.plt.is('android')) {
                                    version = evt.body.success.headers.get("X-Current-APP-Version-a");
                                    force = evt.body.success.headers.get("X-Force-APP-Version-a");
                                }
                                if (version) {
                                    if (this.env.name != 'local' && this.compareVersion(this.env.version, version) == -1) {
                                        // with timeout to make sure, the correct language has been loaded
                                        this.appEvents.publish('app:update');

                                        if (force != '0') {
                                            this.appEvents.publish('app:update:force');
                                        } else {
                                            this.appEvents.publish('app:update:stop');
                                        }
                                    }
                                }
                            } else {
                                if (evt.body.success.headers.get("X-Current-PWA-Version")) {
                                    if (this.env.name != 'local' && this.compareVersion(this.env.version, evt.body.success.headers.get("X-Current-PWA-Version")) == -1) {
                                        // with timeout to make sure, the correct language has been loaded
                                        this.appEvents.publish('app:update');
                                        if (evt.body.success.headers.get("X-Force-PWA-Version") != '0') {
                                            this.appEvents.publish('app:update:force');
                                        } else {
                                            this.appEvents.publish('app:update:stop');
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }),
            catchError((error: any) => {
                if (isExternalUrl(req.url)) {
                    // secure other invalid response from external url....
                } else {
                    // console.log('caught error', error, (window.location.href.match(/\?/g) || []).length < 2);
                    // console.log((error.status === 401 || error.status === 403) && (window.location.href.match(/\?/g) || []).length < 2);
                    // if (!error.json || !error.status) {
                    //     console.log('caught error', typeof error);
                    // }

                    // NOTE[jg] - why do logout for 404? we should maybe just show error message...
                    if (
                        (error.status === 401 || error.status === 403 || error.status === 404) &&
                        (window.location.href.match(/\?/g) || []).length < 2
                    ) {

                        const data = error.error;
                        const isTokenExpired = localStorage.getItem('token') ? this.jwtHelper.isTokenExpired(localStorage.getItem('token')) : true;

                        // console.log(
                        //     error.status,
                        //     isTokenExpired,
                        //     this.jwtHelper.getTokenExpirationDate(localStorage.getItem('token')),
                        //     localStorage.getItem('token')
                        // );

                        /*this.storage.get('token').then(value => {
                            const isTokenExpired = new Promise(resolve => resolve(value ? !this.jwtHelper.isTokenExpired(value) : false));
                            isTokenExpired.then(loggedIn => {
                                if (loggedIn) {
                                    this.appEvents.publish('logout');
                                }
                            });
                        });*/

                        if (isTokenExpired || (data.hasOwnProperty('error') && (data.error === 'token_invalid' || data.error === 'no_valid_token_found' || data.error === 'user_not_found'))) {
                            this.appEvents.publish('logout', data.message);
                        }

                        /*isTokenExpired.then(loggedIn => {
                            if (loggedIn) {
                                this.appEvents.publish('logout');
                            }
                        });*/

                        //this.appEvents.publish('logout');

                    }

                    if (
                        (error.status === 410 && !!error.message && error.message === 'not.found') &&
                        (window.location.href.match(/\?/g) || []).length < 2
                    ) {
                        this.translate = ServiceLocatorService.injector.get(TranslateService);

                        this.appEvents.publish('logout', this.translate.instant('NOT_FOUND'));
                    }

                    if (
                        (error.status === 410 && !!error.message && error.message !== 'not.found') &&
                        (window.location.href.match(/\?/g) || []).length < 2
                    ) {
                        this.appEvents.publish('logout', error.message);
                    }

                    // redirect when permission has changed
                    if (
                        (error.status === 410 && !!error.link) &&
                        !!localStorage.getItem('token')
                    ) {
                        this.translate = ServiceLocatorService.injector.get(TranslateService);
                        // TODO:
                        this.overlayService.showWarning(this.translate.instant('NOT_FOUND_GROUP_RIGHTS'));
                        if (error.link !== 'no-redirect') {
                            console.info(error);
                            // hotfix for old links from backend
                            if (error.link == '/participant/dashboard/participants/list') {
                                this.router.navigate([this.plt.defaultLink + '/home/attendees/list'])
                            } else {
                                this.router.navigate([error.link])
                            }
                        }
                    }
                }

                return throwError(error);
            }));
    }

}

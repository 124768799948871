<ng-container *ngIf="user || isPreview">
    <div *ngIf="viewType == 'grid'"
         class="attendee-box {{type}}"
         [ngClass]="{'not-registered': !attendee.registered_at, 'own': user?.selected_participant_id == attendee.id , 'blocked': (isPreview || !user?.canProfileDetails(attendee))}"
         (click)="showAttendeeDetail(attendee)">
        <ion-row *ngIf="!!attendee.registered_at && user?.selected_participant_id != attendee.id"
                 class="header ion-no-padding">
            <ion-col class="ion-no-padding">
                <ion-badge *ngIf="!hideMatching && user && user.canMatching(attendee)"
                           class="matching">
                    {{ attendee.matching_factor | round }}%
                </ion-badge>
            </ion-col>
            <ion-col class="ion-no-padding ion-text-right">
                <svg-icon [applyClass]="true"
                          src="/assets/icons/ico-bookmark.svg"
                          class="bookmark"
                          [ngClass]="{'active': attendee.bookmarked}"
                          [svgStyle]="{ 'width.px':20 }"
                          (click)="toggleBookmark($event, attendee)">
                </svg-icon>
            </ion-col>
        </ion-row>
        <div class="picture">
            <percentage-circle class="percentage-circle"
                               [radius]="type == 'small' ? (plt.sizeSm ? 50 : 65) : (plt.sizeSm ? 50 : 78)"
                               [matching]="user && user.canMatching(attendee)"
                               [picture]="user && user.canPicture(attendee)"
                               [order]="c"
                               [attendee]="attendee">
            </percentage-circle>
        </div>
        <div class="group-label-wrapper">
            <ion-badge *ngIf="group && group.show_label && group.label.length"
                       class="group-label">
                {{ group.label }}
            </ion-badge>
        </div>
        <h3>
            {{ attendee.firstname }} {{ attendee.lastname }}
        </h3>
        <p>
            <ng-container *ngIf="!attendee.registered_at">{{ 'ATTENDEE_LIST_NOT_YET_A_CONGREET_USER' | translate }}</ng-container>
            <ng-container *ngIf="attendee.registered_at">
                <ng-container *ngIf="user?.selected_participant?.checkProfileRule(attendee, 'profession', 'visible')">
                    <span *ngIf="attendee.profession">{{ attendee.profession | cut: 28 }}</span>
                </ng-container>

                <ng-container
                              *ngIf="(attendee.company && user?.selected_participant?.checkProfileRule(attendee, 'company', 'visible')) || (attendee.city && user?.selected_participant?.checkProfileRule(attendee, 'city', 'visible'))">
                    <ng-container *ngIf="attendee.company && user?.selected_participant?.checkProfileRule(attendee, 'company', 'visible')"><span>{{ attendee.company | cut: 28 }}</span></ng-container>
                    <ng-container
                                  *ngIf="attendee.company && user?.selected_participant?.checkProfileRule(attendee, 'company', 'visible') && attendee.city && user?.selected_participant?.checkProfileRule(attendee, 'city', 'visible')">
                    </ng-container>
                    <ng-container *ngIf="attendee.city && user?.selected_participant?.checkProfileRule(attendee, 'city', 'visible')"><span>{{ attendee.city | cut: 26 }}</span></ng-container>
                </ng-container>
            </ng-container>&nbsp;
        </p>
        <div *ngIf="!!attendee.registered_at && user?.selected_participant_id != attendee.id"
             class="action-buttons">

            <svg-icon [applyClass]="true"
                      src="/assets/icons/ico-like.svg"
                      [ngClass]="{'active': attendee.liked}"
                      class="like"
                      [title]="'ATTENDEE_DETAIL_LIKE' | translate"
                      (click)="toggleLike($event, attendee)">
            </svg-icon>
            <svg-icon *ngIf="user?.canMessage(attendee) && !user?.selected_participant?.not_contactable"
                      [applyClass]="true"
                      src="/assets/icons/ico-message.svg"
                      [title]="'ATTENDEE_DETAIL_MESSAGE' | translate"
                      (click)="goToMessages($event, attendee)">
            </svg-icon>
            <div [title]="'ATTENDEE_DETAIL_CALL' | translate"
                 (click)="openCall($event, attendee)">
                <ion-icon *ngIf="user?.canVideoCall(attendee)"
                          name="videocam-outline">
                </ion-icon>
            </div>
        </div>
    </div>

    <ion-item-sliding *ngIf="viewType == 'lines'"
                      #slidingItems
                      class="{{type}}"
                      [ngClass]="{'not-registered': !attendee.registered_at, 'labeled': group && group.show_label && group.label.length}">
        <ion-row class="ion-no-padding">
            <ion-col size-sm="12"
                     [attr.size-md]="type == 'marketplace-contacts' ? 7 : 8"
                     class="ion-no-padding">

                <ion-item (click)="showAttendeeDetail(attendee)">
                    <ion-badge *ngIf="group && group.show_label && group.label.length"
                               class="group-label">{{ group.label }}</ion-badge>
                    <div class="item-content">
                        <ng-container *ngIf="type == 'marketplace-contacts'">
                            <div class="percentage-wrapper">
                                <percentage-circle class="percentage-circle"
                                                   [matching]="user && user.canMatching(attendee)"
                                                   [picture]="user && user.canPicture(attendee)"
                                                   [attendee]="attendee">
                                </percentage-circle>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="type != 'marketplace-contacts'">
                            <div class="picture">
                                <percentage-circle class="percentage-circle"
                                                   [radius]="32"
                                                   [matching]="user && user.canMatching(attendee)"
                                                   [picture]="user && user.canPicture(attendee)"
                                                   [order]="c"
                                                   [attendee]="attendee">
                                </percentage-circle>
                            </div>
                        </ng-container>


                        <ion-label>
                            <h2>
                                {{ attendee.firstname + ' ' + attendee.lastname | cut: plt.sizeMd ? 32 : 50 }}
                                <ion-badge *ngIf="!hideMatching && !plt.sizeSm && !!attendee.registered_at && user && user.canMatching(attendee) && user?.selected_participant_id != attendee.id"
                                           class="matching">
                                    {{ attendee.matching_factor | round }}%
                                </ion-badge>
                            </h2>
                            <p>
                                <ng-container *ngIf="!attendee.registered_at">{{ 'ATTENDEE_LIST_NOT_YET_A_CONGREET_USER' | translate }}</ng-container>
                                <ng-container *ngIf="attendee.registered_at">
                                    <ng-container *ngIf="user?.selected_participant?.checkProfileRule(attendee, 'profession', 'visible')">
                                        <ng-container *ngIf="plt.sizeSm && attendee.profession">{{ attendee.profession }}<br /></ng-container>
                                        <ng-container *ngIf="!plt.sizeSm && attendee.profession">{{ attendee.profession }}, </ng-container>
                                    </ng-container>
                                    <ng-container
                                                  *ngIf="(attendee.company && user?.selected_participant?.checkProfileRule(attendee, 'company', 'visible')) || (attendee.city && user?.selected_participant?.checkProfileRule(attendee, 'city', 'visible'))">
                                        <ng-container *ngIf="attendee.company && user?.selected_participant?.checkProfileRule(attendee, 'company', 'visible')">{{ attendee.company }}</ng-container>
                                        <ng-container
                                                      *ngIf="attendee.company && user?.selected_participant?.checkProfileRule(attendee, 'company', 'visible') && attendee.city && user?.selected_participant?.checkProfileRule(attendee, 'city', 'visible')">
                                            , </ng-container>
                                        <ng-container *ngIf="attendee.city && user?.selected_participant?.checkProfileRule(attendee, 'city', 'visible')">{{ attendee.city }}</ng-container>
                                    </ng-container>
                                </ng-container>&nbsp;
                            </p>
                        </ion-label>
                        <ion-badge *ngIf="!hideMatching && plt.sizeSm && !!attendee.registered_at && user && user.canMatching(attendee) && user?.selected_participant_id != attendee.id"
                                   class="matching">
                            {{ attendee.matching_factor | round }}%
                        </ion-badge>
                    </div>
                </ion-item>

            </ion-col>

            <ion-col *ngIf="(!plt.sizeSm || (type == 'marketplace-contacts' && attendee.user_id == user.id)) && !!attendee.registered_at"
                     class="ion-no-padding">
                <div class="action-buttons">
                    <ng-container *ngIf="type == 'marketplace-contacts' && (attendee.user_id == user.id); else normalActionButtons">
                        <svg-icon [applyClass]="true"
                                  src="/assets/icons/ico-close.svg"
                                  class="close"
                                  [title]="'MARKETPLACE_POST_CONTACTS_REMOVE_ME' | translate"
                                  (click)="marketplacePostRemoveMyContact(attendee)">
                        </svg-icon>
                    </ng-container>

                    <ng-template #normalActionButtons>
                        <ng-container
                                      *ngIf="(type != 'marketplace-contacts' && user?.selected_participant_id != attendee.id) || (attendee.event_id == user.selected_participant?.event_id && attendee.user_id != user.id)">
                            <svg-icon [applyClass]="true"
                                      src="/assets/icons/ico-like.svg"
                                      [ngClass]="{'active': attendee.liked}"
                                      class="like"
                                      [title]="'ATTENDEE_DETAIL_BOOKMARK' | translate"
                                      (click)="toggleLike($event, attendee)">
                            </svg-icon>

                            <svg-icon [applyClass]="true"
                                      src="/assets/icons/ico-star.svg"
                                      class="bookmark"
                                      [ngClass]="{'active': attendee.bookmarked}"
                                      [title]="'ATTENDEE_DETAIL_LIKE' | translate"
                                      (click)="toggleBookmark($event, attendee)">
                            </svg-icon>
                            <svg-icon *ngIf="user?.canMessage(attendee) && !user?.selected_participant?.not_contactable"
                                      [applyClass]="true"
                                      src="/assets/icons/ico-message.svg"
                                      [title]="'ATTENDEE_DETAIL_MESSAGE' | translate"
                                      (click)="goToMessages($event, attendee)">
                            </svg-icon>
                            <div [title]="'ATTENDEE_DETAIL_CALL' | translate"
                                 (click)="openCall($event, attendee)">
                                <ion-icon *ngIf="user?.canVideoCall(attendee)"
                                          name="videocam-outline">
                                </ion-icon>
                            </div>
                        </ng-container>
                    </ng-template>

                </div>
            </ion-col>
        </ion-row>

        <ng-container *ngIf="!!attendee.registered_at && plt.sizeSm && (type != 'marketplace-contacts' || attendee.event_id == user.selected_participant?.event_id) && user?.id != attendee.user_id">
            <ion-item-options side="start">
                <ion-item-option (click)="toggleBookmark($event, attendee)"
                                 [color]="attendee.bookmarked ? 'secondary':'light'">
                    <div class="swipe-button">
                        <svg-icon [applyClass]="true"
                                  src="/assets/icons/ico-bookmark.svg"
                                  class="bookmark"
                                  [ngClass]="{'active': attendee.bookmarked}"
                                  [svgStyle]="{ 'width.px':20 }">
                        </svg-icon>
                        {{'ATTENDEE_LIST_BUTTON_BOOKMARK' | translate }}
                        <ion-spinner *ngIf="attendee.bookmarkLoading"
                                     name="dots"></ion-spinner>
                    </div>
                </ion-item-option>
                <!--  *ngIf="user.canMessage(attendee) && user.selected_participant?.not_contactable && user.selected_participant?.id == attendee.id" -->
                <ion-item-option (click)="toggleLike($event, attendee)"
                                 class="like"
                                 [color]="attendee.liked ? 'primary':'light'">
                    <div class="swipe-button">
                        <svg-icon [applyClass]="true"
                                  src="/assets/icons/ico-like.svg"
                                  [ngClass]="{'active': attendee.liked}">
                        </svg-icon>
                        {{'ATTENDEE_LIST_BUTTON_LIKE' | translate }}
                        <ion-spinner *ngIf="attendee.likeLoading"
                                     name="dots"></ion-spinner>
                    </div>
                </ion-item-option>
            </ion-item-options>
            <ion-item-options side="end">
                <ion-item-option *ngIf="homeUrlSegment != 'preview' && user?.canMessage(attendee) && !user?.selected_participant?.not_contactable"
                                 color="secondary"
                                 (click)="goToMessages($event, attendee)">
                    <div class="swipe-button">
                        <svg-icon [applyClass]="true"
                                  src="/assets/icons/ico-message.svg">
                        </svg-icon>
                        {{'ATTENDEE_LIST_BUTTON_CHAT' | translate }}
                    </div>
                </ion-item-option>
                <ion-item-option *ngIf="homeUrlSegment != 'preview' && user?.canVideoCall(attendee)"
                                 color="secondary"
                                 (click)="openCall($event, attendee)">
                    <div class="swipe-button">
                        <ion-icon name="videocam-outline"></ion-icon>
                        {{'ATTENDEE_DETAIL_CALL' | translate }}
                    </div>
                </ion-item-option>
            </ion-item-options>
        </ng-container>
    </ion-item-sliding>
</ng-container>
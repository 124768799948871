import * as moment from "moment";

import { Participant } from "./participant";
import { CustomParticipant } from "./custom-participant";
import { Marketplace } from "./marketplace";
import { MarketplaceLike } from "./marketplace-like";
import { MarketplaceKeyword } from "./marketplace-keyword";
import { MarketplaceComment } from "./marketplace-comment";
import { MarketplaceCategory } from "./marketplace-category";
import { MarketplacePostDetail } from "./marketplace-post-detail";
import { MarketplaceHeadline } from "./marketplace-headline";

export class MarketplacePost {
    id: number;
    user_id: number;
    marketplace_id: number;
    marketplace_category_id: number;
    custom_participant_id: number;
    participant_id: number;

    status: number;
    name: string;
    description: string;
    price: number;
    coin_price: number;
    phone: string;
    email: string;
    image: string;
    image_url: string;
    pinned: boolean = false;
    promoted: boolean = false;
    refuse_reason: string;
    digital_purchase_link: string;

    updated_at: string;
    date_updated_at: Date = new Date();
    created_at: string;
    date_created_at: Date = new Date();

    selected = false;

    likes: MarketplaceLike[] = [];
    keywords: any[] = [];
    keywordsUnique: any[] = [];
    comments: MarketplaceComment[] = [];
    category: MarketplaceCategory = new MarketplaceCategory;
    editComment: MarketplaceComment = new MarketplaceComment;
    marketplace: Marketplace = new Marketplace;

    participant: Participant;
    custom_participant: CustomParticipant = new CustomParticipant;

    longTextVisible: boolean = false;
    commentsVisible: boolean = false;
    sliceLinesPosition: number = 0;

    details: MarketplacePostDetail[] = [];

    groupedDetails = [];
    groupedHeadlineDetails = [];
    groupedDetailsDeleted = [];

    contacts: MarketplaceHeadline[] = [];

    detailTypes = [
        {
            name: 'paragraph',
            icon: 'text',
            group: [
                'paragraph'
            ]
        },
        {
            name: 'image',
            icon: 'image',
            group: [
                'image'
            ]
        },
        {
            name: 'link',
            icon: 'link',
            group: [
                'link'
            ]
        }
    ];

    /**
     * constructor
     *
     * @return void
     */
    public constructor(init?) {
        if (!!init) {
            Object.assign(this, init);
            if (this.updated_at) this.date_updated_at = moment.utc(this.updated_at).toDate();
            if (this.created_at) this.date_created_at = moment.utc(this.created_at).toDate();

            if (init.custom_participant) {
                this.custom_participant = new CustomParticipant(init.custom_participant);
            }

            if (this.participant) {
                this.participant = new Participant(this.participant);
            }

            if (this.category) {
                this.category = new MarketplaceCategory(this.category);
            }

            if (this.marketplace) {
                this.marketplace = new Marketplace(this.marketplace);
            }

            // slice text by position of 4th line break
            let i = 0;
            let shortText = '';
            let textLines = this.description ? this.description.split(/\r\n|\r|\n/) : [];

            textLines.forEach(item => {
                if (i < 4) {
                    i++;
                    shortText += item;
                } else {
                    this.sliceLinesPosition = shortText.length;
                }
            });

            // initiate comments
            this.comments = this.comments.map(comment => new MarketplaceComment(comment));

            // initiate contacts
            this.contacts = this.contacts.map(contact => new MarketplaceHeadline(contact));

            // initiate likes
            this.likes = this.likes.map(like => new MarketplaceLike(like));

            // initiate keywords
            this.keywords = this.keywords.map(keyword => {
                return {
                    keyword: new MarketplaceKeyword(keyword.keyword),
                    marketplace_keyword_id: keyword.marketplace_keyword_id
                }
            });

            this.initKeywordsUnique();

            // load details
            this.initDetails();
        }
    }

    /**
     * return real participant if possible
     * @return Participant || false
     */
    public getRealParticipant() {
        let participant = null;

        // selected participant by organizer
        if (this.custom_participant) {
            if (this.custom_participant.participant_id) {
                participant = this.custom_participant.participant;
            }
        }

        // normal participant
        else if (this.participant_id) {
            participant = this.participant;
        }

        return participant || false;
    }

    /**
     * initiate the array with unique keywords
     */
    public initKeywordsUnique() {
        this.keywordsUnique = [];

        this.keywords.forEach(keyword => {
            if (!this.keywordsUnique.filter(item => {
                return item.keyword.name == keyword.keyword.name;
            }).length) {
                this.keywordsUnique.push(new MarketplaceKeyword(keyword));
            }
        });
    }

    public initDetails() {
        if (this.details && !this.groupedDetails.length  && !this.groupedHeadlineDetails.length) {

            let groupedDetails = [];

            this.details.forEach((detail) => {

                if (!groupedDetails.hasOwnProperty(detail.group_index)) {
                    // create default options object in case, if it is missing
                    if (!detail.options) {
                        detail.options = {};
                    }
                    groupedDetails[detail.group_index] = {
                        deleted: false,
                        type: detail.group_type,
                        line: detail.options.line ? true : false,
                        lang: detail.options.lang ? detail.options.lang : '',
                        headline: detail.options.headline ? true : false,
                        details: []
                    };
                }

                groupedDetails[detail.group_index].details.push(new MarketplacePostDetail(detail));
            });

            // check if there is some content, to eventually disable line when group is empty
            groupedDetails.forEach((group) => {
                let visible = false;
                
                group.details.forEach((detail) => {
                    if (detail.value) {
                        visible = true;
                    }
                });
                if (!visible) {
                    // there is nothing to show, so we will disable the line
                    group.visible = false;
                }
                // add final grouped detail to final array

                if (group.headline) {
                    this.groupedHeadlineDetails.push(group);
                } else {
                    this.groupedDetails.push(group);
                }
            });
        }
    }

    public hasValue() {
        return this.name || this.description || this.image || this.keywords.length > 0;
    }
}

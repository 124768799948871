export class EventTranslation
{
    id: number;
    locale: string;
    name: string;
    short_description: string;
    description: string;
    location: string;
    agenda: string;
    speaker: string;
    directions: string;
    tab_marketplace: string;
}

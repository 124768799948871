export const config = {
	"pwa": "https://pwa.congreet.com",
	"prodPwa": "https://pwa.congreet.com",
	"deeplink": "https://congreet.app.link",
	"appStoreLink": "https://itunes.apple.com/us/app/congreet/id1286636661?l=de&ls=1&mt=8",
	"playStoreLink": "https://play.google.com/store/apps/details?id=com.congreet.app2",
	"availableLangs": [
		"de",
		"en"
	],
	"hideSidebarPublicPages": false,
	"publicPagesLang": false,
	"hideLogout": false,
	"hideSettingsPasswordReset": false,
	"atsAnonymous": true,
	"noIframeRediret": "",
	"showInstallPrompt": true,
	"showCredentialWallet": false,
	"hideRatingInMembers": false,
	"sameSiteAssets": false,
	"maxAppointmentBlockSize": 20,
	"liveTranslation": true,
	"liveTranslationProd": false,
	"benefitUrl": {
		"en": "https://www.congreet.com/en/features-community-software-en/",
		"de": "https://www.congreet.com/de/features-community-software/"
	}
}